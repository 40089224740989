module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://login.rekord-fenster.com/graphql","develop":{"nodeUpdateInterval":10000,"hardCacheMediaFiles":true,"hardCacheData":true},"html":{"useGatsbyImage":false,"createStaticFiles":false,"generateWebpImages":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateAvifImages":false,"placeholderType":"dominantColor"},"schema":{"timeout":240000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"type":{"Comment":{"exclude":true},"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"PostFormat":{"exclude":true},"RootQuery":{"exclude":true,"excludeFieldNames":["viewer","node","schemaMd5"]},"Tag":{"exclude":true},"User":{"exclude":true},"UserRole":{"exclude":true}},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"rekord-fenster-frontend","short_name":"rekord","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/rekord-fenster-fav.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2ef19855331a356f95b9776db0305efd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
